import React from 'react';
import Layout from "../../components/layout";

const Wangara = () => {
    return (
        <Layout>
            <h1>Wangara</h1>
            <h2>Instructor</h2>
            <p>Sensei Jody Blunden<br/>
                Email: <a href="mailto:north@togka.com.au">north@togka.com.au</a></p>
            <p><strong>Location:</strong><br/>
                Unit 2/26 Barretta Rd Wangara<br/>
                (Shobukan Martial Arts)</p>

            <p><strong>Day/Time:</strong><br/>
                Monday and Wednesday Nights<br/>
                Junior Grades 6.00pm - 7.00pm<br/>
                Senior Grades 7.00pm - 8.15pm<br/>
            </p>

            <p><strong>Fees:</strong></p>
            <p>
                $30.00 per week<br/>
            </p>

            <p>
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3011.448018178409!2d115.81975911485117!3d-31.79255148128045!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2a32ad098d401925%3A0x5e46c5fd8576d572!2s2%2F26%20Baretta%20Rd%2C%20Wangara%20WA%206065!5e1!3m2!1sen!2sau!4v1619271362021!5m2!1sen!2sau"
                    width="400" height="300" frameborder="0" title="cottesloe"></iframe>
            </p>

        </Layout>);
}

export default Wangara;
